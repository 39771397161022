MobilusIugu = {
  init: function(testingMode) {
    Iugu.setAccountID("9F5FFF606C5B4B718AFBAB5DC1C918FB");
    Iugu.setTestMode(testingMode);
    Iugu.setup();
  },
  createPaymentToken: function(data, successCallback, errorCallback) {
    Iugu.createPaymentToken(data, successCallback, errorCallback);
  },
  createCreditCard: function(number, month, year, first_name, last_name, verification_value) {
    let cc = Iugu.CreditCard(number, month, year, first_name, last_name, verification_value);
    return cc;
  },
  validateCreditCardNumber: function(number) {
    return Iugu.utils.validateCreditCardNumber(number);
  },
  validateCVV: function(cvv, brand) {
    return Iugu.utils.validateCVV(cvv, brand);
  },
  validateExpiration: function(month, year) {
    return Iugu.utils.validateExpiration(month, year);
  },
  isBlockedByAdBlock: function() {
    return Iugu.utils.isBlockedByAdBlock();
  },
  getBrandByCreditCardNumber: function(number) {
    return Iugu.utils.getBrandByCreditCardNumber(number);
  }
}
